const videos = [
    {
      thumbURL: "thumb/TRUST.png",
      title: "TRUST & COMMUNITY ENGAGEMENT",
      start: 0
    },
    {
      thumbURL: "thumb/RUMORS.png",
      title: "RUMORS",
      start: 3
    },
    {
      thumbURL: "thumb/COMPLEX_EMERGENCIES.png",
      title: "COMPLEX EMERGENCIES",
      start: 5
    },
    {
      thumbURL: "thumb/ETHICAL_DILEMMAS.png",
      title: "ETHICAL DILEMMAS",
      start: 7
    },
    {
      thumbURL: "thumb/TECHNOLOGY.png",
      title: "TECHNOLOGY",
      start: 8
    },
    {
      thumbURL: "thumb/WEBINAR.png",
      title: "MAIN WEBINAR",
      start: 10
    }

  ];

export { videos }