import React, { useState, useEffect } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { info } from '../assets/data/survey.js'
import '../css/survey.css'

const Choice = ({ data, QIndex, AnsIndex, addAns}) => {

    const [disabled, setDisabled] = useState(false)

    useEffect(() => {

        setDisabled(false)

    },[QIndex])

    return (
        <Button className={disabled ? 'next-button-disabled py-3 btn-block' : 'next-button py-3 btn-block'} onClick={() => { setDisabled(true); addAns(data) }}>
            {data}
        </Button>
    )
}

let jsonPayload = {};


const Survey = () => {

    const [index, setIndex] = useState(0)
    const [finish, setFinish] = useState(false)

    const surveyLength = info.length - 1;

    useEffect(() => {
        
        jsonPayload[`Q${index+1}`] = {};
        jsonPayload[`Q${index+1}`]['Question'] = info[index].Question
        jsonPayload[`Q${index+1}`]['Answers'] = []

    },[index])

    useEffect(() => {

        if(finish){
        
        fetch('/api/survey/save/EBODAC', {
            method: 'POST',
            body: JSON.stringify(jsonPayload), // data can be `string` or {object}!
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            }).then(response => response.json())
            .then(data => {console.log(data)
            }).catch(error => { console.error('Error:', error) });
        
        }

    },[finish])

    const updateResult = (answer) => {

        jsonPayload[`Q${index+1}`]['Answers'].push(answer);

    }
    
    const renderNextButton = (surveyLength) => {

        var content = surveyLength !== index
            ? <Button className='finish-button btn-block py-3' onClick={() => { setIndex(index + 1) }}>GO TO NEXT QUESTION</Button>
            : <Button className='finish-button btn-block py-3' onClick={() => { setFinish(true) }}>Finish Survey!</Button>
    
        return content
    
    }

    return (
            <Row className="justify-content-center my-5 px-5 py-5 bg-black">
                {!finish ?
                    <Col>
                        <Row className="justify-content-center text-white my-3 pt-4 border-top">
                            <h2>{info[index].Question}</h2>
                        </Row>
                        <Row className="justify-content-center text-white my-3 pb-4 border-bottom">
                            <h3>Select Answer Below</h3>
                        </Row>
                        <Row className="justify-content-center text-white my-3">
                            {info[index].Answers.map((ans, indx) => 
                                <Col key={indx} md={6} lg={4} className="my-3"><Choice data={ans} QIndex={index} AnsIndex={indx} addAns={updateResult}></Choice></Col>
                            )}
                        </Row>
                        <Row className="mt-5">
                            <Col md={{ span: 4, offset: 8 }}>
                                {renderNextButton(surveyLength)}
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col className="text-center text-white">
                        <h2 className="my-4">Thank you for completing this learning module.</h2>
                        <img src={require("../assets/images/orange_box.png")} alt="Spacer"></img>
                        <h2 className="my-4">Please help us by sharing this module!</h2>
                        <Row className="my-5 justify-content-center">
                            <img className="mx-4" src={require("../assets/images/ikana-logo.png")} width="130" height="60" alt="Ikana Media" loading="lazy"></img>
                            <img className="mx-4" src={require("../assets/images/ebodac1.png")} width="135" height="65" alt="EBODAC" loading="lazy"></img>
                        </Row>
                    </Col>
                }
            </Row>
    );

}
export default Survey;
