const videos = [
    {
      videoId: "482673701",
      thumbURL: "thumb/TRUST.png",
      title: "TRUST VIDEO",
      section: "SECTION 1: TRUST & COMMUNITY ENGAGEMENT",
    },
    {
      videoId: "482673836",
      thumbURL: "thumb/COMMUNITY_ENGAGEMENT.png",
      title: "COMMUNITY ENGAGEMENT VIDEO",
      section: "SECTION 1: TRUST & COMMUNITY ENGAGEMENT",
    },
    {
      videoId: "489442000",
      thumbURL: "thumb/TRUST_PANEL.png",
      title: "TRUST & COMMUNITY PANEL",
      section: "SECTION 1: TRUST & COMMUNITY ENGAGEMENT",
    },
    {
      videoId: "482673781",
      thumbURL: "thumb/RUMORS.png",
      title: "RUMORS VIDEO",
      section: "SECTION 2: RUMORS",
    },
    {
      videoId: "489442469",
      thumbURL: "thumb/RUMORS_PANEL.png",
      title: "RUMORS PANEL",
      section: "SECTION 2: RUMORS",
    },
    {
      videoId: "482673982",
      thumbURL: "thumb/COMPLEX_EMERGENCIES.png",
      title: "COMPLEX EMERGENCIES VIDEO",
      section: "SECTION 4: COMPLEX EMERGENCIES"
    },
    {
      videoId: "489442693",
      thumbURL: "thumb/COMPLEX_PANEL.png",
      title: "COMPLEX EMERGENCIES PANEL",
      section: "SECTION 4: COMPLEX EMERGENCIES"
    },
    {
      videoId: "482674150",
      thumbURL: "thumb/ETHICAL_DILEMMAS.png",
      title: "ETHICAL DILEMMAS VIDEO",
      section: "SECTION 5: ETHICAL DILEMMAS",
    },
    {
      videoId: "482674195",
      thumbURL: "thumb/TECHNOLOGY.png",
      title: "TECHNOLOGY VIDEO",
      section: "SECTION 6: TECHNOLOGY"
    },
    {
      videoId: "489443057",
      thumbURL: "thumb/TECH_PANEL.png",
      title: "TECHNOLOGY PANEL",
      section: "SECTION 6: TECHNOLOGY"
    },
    {
      videoId: "489443421",
      thumbURL: "thumb/WEBINAR.png",
      title: "MAIN WEBINAR",
      section: "SECTION 7: WEBINAR"
    }

  ];

export { videos }