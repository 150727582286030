import React from 'react';
import Container from 'react-bootstrap/Container'
import { Row, Col, Card, OverlayTrigger, Popover } from 'react-bootstrap'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { videos } from '../assets/data/menu.js'
import { Link } from "react-router-dom";


const Menu = () => {

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Information</Popover.Title>
            <Popover.Content>
                <p>To begin, select one of the sections below.</p>
                <hr />
                <p>This will take you to the start of the section. From there a slide show will appear that has all the videos listed.</p>
            </Popover.Content>
        </Popover>
    );

    return (
        <Container fluid className="py-5 bg-black">

            <Row className="justify-content-center">

                <Col xl={10} className="text-center">
                    <Row className="justify-content-center my-5 pb-2 text-white border-bottom">
                        <Col md={{ span: 8, offset: 2 }}>
                            <h3 className="pb-2">Ebola Vaccine Deployment, Acceptance and Compliance</h3>
                        </Col>
                        <Col>
                            <OverlayTrigger delay={{ show: 200, hide: 500 }} placement="bottom" overlay={popover}>
                                <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ color: '#ff590f'}} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {videos.map((video, index) =>
                            <Col lg={4} key={index} className="d-flex justify-content-center">
                                <Link to={`/module/${video.start}`}>
                                    <Card bg="black" border="dark" style={{ width: '22rem' }} className="transform my-3">
                                        <Card.Img variant="top" src={require('../assets/images/' + video.thumbURL)} />
                                        <Card.Body>
                                            <Card.Title className="text-orange"><h4>{video.title}</h4></Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Menu;
