import React from 'react';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

function NavMenu () {

        return (
        <Container>
        <Navbar bg="white" expand="lg">
            <Navbar.Brand href="/">
                <img src={require("../assets/images/ikana-logo.png")} width="100" height="45" alt="" loading="lazy"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="https://ikanahealth.com">
                        <p><u>IkanaHealth</u></p>
                    </Nav.Link>
                    <Nav.Link href="https://www.ebovac.org/ebodac/">
                        <p><u>EBODAC</u></p>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </Container>
        );
}

export default NavMenu;