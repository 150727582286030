import React, { useState, useRef } from 'react'
import  Survey from './Survey';
import { useParams } from 'react-router-dom';
import { Button, Container, Image, Row, Col, OverlayTrigger, Popover, Spinner, ButtonGroup } from 'react-bootstrap'
import Slider from "react-slick";
import Vimeo from '@u-wave/react-vimeo';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { videos } from '../assets/data/videoData.js'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/module.css'

const Module = () => {

    const { urlIndex } = useParams();

    const [showPlayer, setShowPlayer] = useState(true)
    const [lastVideo, setLastVideo] = useState(false)
    const [showSurvey, setShowSurvey] = useState(false)
    const [index, setIndex] = useState(Number(urlIndex))
    const slider = useRef(null)
    const vidRef = useRef(null)
    const spRef = useRef(null)

    const videoLength = videos.length - 1;

    const goToSlide = (video) => {

        if (videos[index] !== undefined) {

            slider.current.slickGoTo(video, false);

            document.getElementById(`img${index}`).classList.remove('image-opacity');
            document.getElementById(`np${index}`).style.visibility = "hidden";

            if (null !== spRef.current) {
                spRef.current.style.display = ""
            }

            if (!showPlayer)
                setShowPlayer(true)

            if (lastVideo)
                setLastVideo(false)
            
            if (showSurvey)
                setShowSurvey(false)

            setIndex(video);

        }

    };

    const start = () => {
        document.getElementById(`img${index}`).classList.add('image-opacity');
        document.getElementById(`np${index}`).style.visibility = "visible";

        if (null !== vidRef.current) {
            window.scrollTo({
                top: vidRef.current.offsetTop - 15,
                behavior: "smooth"
            })
        }

        if (null !== spRef.current) {
            spRef.current.style.display = "none";
        }

    }

    const end = () => {

        if (index === videoLength) {
            setLastVideo(true)
        }

        setShowPlayer(false)

    }

    const popover = (
        <Popover id="module-popover">
            <Popover.Title as="h3">Information</Popover.Title>
            <Popover.Content>
                <p>Click on a video to watch it.</p><hr />
                <p>Once the video ends a menu will appear. You can play again the current video, go to the next one on the slides or to the main menu.</p><hr />
                <p>Use side arrows or swipe on mobile to browse through the list.</p>
            </Popover.Content>
        </Popover>
    );

    var settings =
    {
        infinite: false,
        dots: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: index,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };

    return (
        <Container fluid className="bg-black pt-4">
            <Row className="justify-content-center my-5">
                <Col sm lg={9}>
                    <Row className="my-3">
                        <Col md={10}>
                            <h2 className="text-white text-responsive">{videos[index].section}</h2>
                        </Col>
                        <Col className="text-right" ref={spRef}>
                            <Spinner animation="border" variant="light"/>
                        </Col>
                        <Col className="text-right">
                            <OverlayTrigger delay={{ show: 200, hide: 500 }} placement="left" overlay={popover}>
                                <FontAwesomeIcon icon={faInfoCircle} size="2x" style={{ color: '#ff590f' }} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Slider {...settings} ref={slider} className="mx-1">{
                        videos.map((video, i) => (
                            <div key={i} className="px-4">
                                <Row className="slider-container">
                                    <Image id={`img${i}`} alt={video.title} fluid src={require('../assets/images/' + video.thumbURL)} onClick={() => { goToSlide(i) }}></Image>
                                    <div id={`np${i}`} className="centered">NOW PLAYING</div>
                                </Row>
                                <Row className="text-white bg-orange pt-2">
                                    <Col>
                                        <p className="text-center font-weight-bold">{video.title}</p>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                    </Slider>
                </Col>
            </Row>
            {showPlayer ?
            <Row ref={vidRef} className="justify-content-center mt-4 pb-5">
                <Col sm lg="9">
                    <Vimeo
                        video={videos[index].videoId}
                        responsive={true}
                        onEnd={end}
                        autoplay={true}
                        onPlay={start}
                        color="ff590f"
                    />
                </Col>
            </Row>
            :
            <Row className="justify-content-center mt-4 bg-wv-1">
                {!showSurvey ?
                <Col xs lg={8} className="my-5 text-dark">
                    <Row className="my-5">
                        <Col>
                            <h2 className="text-responsive">{`JUST FINISHED: ${videos[index].title}`}</h2>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col>
                            <img src={require("../assets/images/orange_box.png")} alt="Spacer"></img>
                        </Col>
                    </Row>
                    <Row className="my-5">
                        <Col>
                            <h3 className="text-responsive">{videos[index].section}</h3>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={10}>
                        <ButtonGroup aria-label="ScrollButtons">
                            <Button className="next-button py-3" onClick={() => { goToSlide(index) }}>
                                PLAY AGAIN: {videos[index].title}
                            </Button>
                            {!lastVideo ?
                            <Button className="next-button py-3" onClick={() => { goToSlide(index + 1) }}>
                                NEXT VIDEO: {videos[index + 1].title}
                            </Button>
                            :
                            <Button className="next-button py-3" onClick={() => { setShowSurvey(true) }}>
                                NEXT: SURVEY
                            </Button>
                            }
                            </ButtonGroup>                          
                        </Col>
                        <Col className="text-right">
                        <Link to={`/menu`}>
                            <Button className="next-button py-3" onClick={() => { goToSlide(index + 1) }}>
                                MAIN MENU
                            </Button> 
                            </Link>
                        </Col>
                    </Row>     
                </Col>
                :
                <Col md lg={8}>
                    <Survey></Survey>
                </Col>
                }
            </Row> 
            }
        </Container>
    );
}

export default Module;
