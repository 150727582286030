import React from 'react';

import  Layout  from './components/Layout';
import  Intro  from './components/Intro';
import  Module from './components/Module';
import  Menu from './components/Menu';

import './css/app.css';

import { Route, Switch } from "react-router-dom";

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={Intro}/>
        <Route path="/menu" component={Menu}/>
        <Route path="/module/:urlIndex" component={Module}/>
      </Switch>
    </Layout>
);
}

export default App;
