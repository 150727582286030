const info = [{
    Question: "Was this Educational Module helpful to you?",
    Answers: ["Yes", "No"]
},
{
    Question: "Which topic(s) interested you the most?",
    Answers: ["Trust & Community Engagement", "Rumors","Complex Emergencies","Ethical Dilemmas","Technology"]
},
{
    Question: "Which topic(s) interested you the least?",
    Answers: ["Trust & Community Engagement", "Rumors","Complex Emergencies","Ethical Dilemmas","Technology"]
}]

export { info }