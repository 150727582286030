import React from 'react';
import Container from 'react-bootstrap/Container'
import { Row, Col, Image, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import '../css/intro.css'

const Amr = (props: any) => {

    return (
        <Container fluid className="py-5 bg-wv-1">

                <Row className="justify-content-center">

                    <Col xs lg="5" className="text-center">

                        <Image src={require("../assets/images/ebodac.png")} rounded fluid/>

                        <Row className="justify-content-center my-5 font-weight-bold text-white">
                            <Col className="bg-orange">
                                <h4 className="mt-3 mb-3">EBODAC LEARNING MODULE</h4>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col className="text-dark font-weight-normal lead">
                                <p>The EBODAC project (Ebola Vaccine Deployment, Acceptance and Compliance) was set up to develop strategies and tools to promote the acceptance and uptake of new Ebola vaccines, to ensure that the right person receives the right vaccine at the appropriate time.
                                    <hr/>The project supported clinical trials of Ebola vaccines in Sierra Leone, Uganda and DRC, as well as a vaccination campaign in Rwanda, while simultaneously preparing for the future deployment of a licensed vaccine through a series of linked projects focused on communications, community engagement, and enabling technologies.
                                    <hr/>These panel discussions are a culmination of the lessons learned during the six years of supporting Ebola vaccine clinical trials and helping countries to prepare for future outbreaks. 
                                    <hr/>EBODAC is run by a consortium of four partners: The London School of Hygiene & Tropical Medicine, Janssen Pharmaceutica NV, World Vision and the Grameen Foundation.
                                </p>
                            </Col>
                        </Row>

                        <Row className="justify-content-center my-4">
                            <Col xs="8">
                                <Link to="/menu" className="text-white">
                                    <Button size="lg" className="begin-experience-button circle font-weight-bold p-3 py-2">
                                    BEGIN EXPERIENCE
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="justify-content-center mt-5">
                            <p className="font-weight-light">
                                This project has received funding from the Innovative Medicines Initiative 2 Joint Undertaking under grant agreement EBODAC (grant nr. 115847). 
                                This Joint undertaking receives support from the European Union’s Horizon 2020 research and innovation programme and EFPIA.
                            </p>
                        </Row>
                    </Col>
                </Row>
        </Container>
    );
}

export default Amr;
