import React from 'react';
import NavMenu from './NavMenu';

const Layout = (props) => {

  const year = new Date().getFullYear();

  return (
    <div>
      <NavMenu />
      {props.children}
      <footer className="py-4 text-center">Copyright © {year} Ikana Health Action Lab </footer>
    </div>
  );
}

export default Layout;
